import React, { useContext, useState } from 'react'
import { AuthContext } from '../AuthContext';
import {
  ArticleContext,
  updateArticleActionType,
  saveArticleActionType,
  errorActionType,
  doneSavingArticleActionType,
  deleteActionType,
} from './EditorContext'
import Paper from '@mui/material/Paper'
import FabMenu, { FabContext, WithLoading } from '../FastAccessMenu';
import TextField from '@mui/material/TextField'
import { deleteArticle, postNode, putArticle, putNode } from '../EditorAPIClient';

import CheckIcon from '@mui/icons-material/Check'
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from './constants'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Article from '../Article';

import { Modal } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import Fade from '@mui/material/Fade';
import { Scrollbar } from 'react-scrollbars-custom';
import Container from '@mui/material/Container';


const ReactEditorJS = createReactEditorJS()


async function articleDiff(authContext, article, modification) {

  const { token, user } = authContext.tokens.strapi;
  modification = {...article, ...modification}

  for (let i = 0; i < modification.nodes.length; i++) {
    const old_node = article.nodes.find(n => n.id === modification.nodes[i].id)
    if (old_node === undefined) {
      // Creation of a new node
      const strapiResponse = await postNode(token, [article.id], [user.id], modification.nodes[i])
      modification.nodes[i].sid = strapiResponse.id
    } else {
      modification.nodes[i].sid = old_node.sid
      if (JSON.stringify(modification.nodes[i]) !== JSON.stringify(old_node)) {
        // Update existing but modified node
        let modif = modification.nodes[i]
        await putNode(token, old_node.sid, [article.id], [user.id], modif)
      }
    }
  }

  for (let i = 0; i < article.nodes.length; i++) {
    const new_node = modification.nodes.find(n => n.id === article.nodes[i])
    if (new_node === undefined) {
      // TODO Delete node article.nodes[i] if it is not referenced by any article anymore
      // TODO deport this work to the backend
    }
  }

  return modification;
}


function saveHandlerFactory(articleContext, authContext, setLoading, setSuccess) {
  async function saveHandler() {
    setLoading(true);
    let { modification } = articleContext;
    const { article, dispatch } = articleContext;
    modification = await articleDiff(authContext, article, modification);
    dispatch({type: saveArticleActionType, payload: modification})
    const { id, title, description, nodes } = modification;
    const { token } = authContext.tokens.strapi;
    try {
      await putArticle(token, id, title, description, nodes.map(node => node.sid))
      dispatch({type: doneSavingArticleActionType})
      setLoading(false)
      setSuccess(true)
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setSuccess(false)
    } catch (err) {
      dispatch({type: errorActionType, payload: err.message})
    }
  }
  return saveHandler;
}


function deleteHandlerFactory(articleContext, authContext) {
  async function deleteHandler() {
    const { dispatch, article } = articleContext;
    const { id } = article;
    const { token } = authContext.tokens.strapi;
    try {
      await deleteArticle(token, id);
      dispatch({type: deleteActionType})
    } catch (err) {
      dispatch({type: errorActionType, payload: err.message})
    }
  }
  return deleteHandler;
}


function DeleteFabButton(props) {
  
  const authContext = useContext(AuthContext);
  const articleContext = useContext(ArticleContext);
  
  const { 
    showConfirmationDemand,
    setShowConfirmationDemand,
  } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    p: 4,
  };
  
  return <>
    <Modal 
      open={showConfirmationDemand}
      onClose={() => {setShowConfirmationDemand(false)}}
    >
      <Fade in={showConfirmationDemand}>
        <Paper variant="header" sx={style} >
          <Typography sx={{p: 2}} id="modal-modal-title" variant="h4" component="h2">
            <WarningIcon sx={{mr: 2}}/>
            Are you sure you want to delete this article?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Performing this action will permanently delete the article and its content!
          </Typography>
          <p>
            <Button
              sx={{ marginLeft: "18%", marginRight: "10%" }}
              onClick={() => {setShowConfirmationDemand(false)}}
            >Cancel</Button>
            <Button 
              variant="danger"
              sx={{ marginLeft: "10%" }}
              onClick={deleteHandlerFactory(articleContext, authContext)}
            >Delete</Button>
          </p>
        </Paper>
      </Fade>
    </Modal>
  </>
}


function ArticleEditorFormFabMenu(props) {
  const { toggleDarkMode } = useContext(FabContext);
  const authContext = useContext(AuthContext);
  const articleContext = useContext(ArticleContext);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(articleContext.status === "PUTARTICLE");
  const [showConfirmationDemand, setShowConfirmationDemand] = React.useState(false);
  
  const articleEditorFormFabContext = {
    saveArticle: {
      fabProps: {
        color: success ? "success": "warning",
        "aria-label": "save-article",
        onClick: saveHandlerFactory(articleContext, authContext, setLoading, setSuccess),
        disabled: articleContext.status !== "DIRTY",
        tooltip: "Save the article",
      },
      fabContent: success ? <CheckIcon /> : <WithLoading loading={loading}><SaveIcon/></WithLoading>,
    },
    deleteArticle: {
      fabProps: {
        color: "danger",
        "aria-label": "delete-article",
        onClick: () => setShowConfirmationDemand(true),
        tooltip: "Delete the article",
      },
      fabContent: <DeleteIcon/>
    },
    viewArticle: {
      fabProps: {
        color: "info",
        "aria-label": "view-article",
        onClick: props.togglePreview,
        tooltip: "Preview the article",
      },
      fabContent: <PreviewIcon/>
    },
    toggleDarkMode,
  }
  return <>
    <DeleteFabButton showConfirmationDemand={showConfirmationDemand} setShowConfirmationDemand={setShowConfirmationDemand}/>
    <FabContext.Provider value={articleEditorFormFabContext}>
      <FabMenu/>
    </FabContext.Provider>
  </>;
}


function ArticleEditorPreviewFabMenu(props) {
  const { toggleDarkMode } = useContext(FabContext);
  const authContext = useContext(AuthContext);
  const articleContext = useContext(ArticleContext);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(articleContext.status === "PUTARTICLE");

  const articleEditorPreviewFabContext = {
    saveArticle: {
      fabProps: {
        color: success ? "success": "warning",
        "aria-label": "save-article",
        onClick: saveHandlerFactory(articleContext, authContext, setLoading, setSuccess),
        disabled: articleContext.status !== "DIRTY",
        tooltip: "Save the article",
      },
      fabContent: success ? <CheckIcon /> : <WithLoading loading={loading}><SaveIcon/></WithLoading>,
    },
    backToEditArticle: {
      fabProps: {
        color: "info",
        "aria-label": "view-article",
        onClick: props.togglePreview,
        tooltip: "Back to article edition",
      },
      fabContent: <ArrowBackIosNewIcon />
    },
    toggleDarkMode,
  }
  return <FabContext.Provider value={articleEditorPreviewFabContext}>
    <FabMenu/>
  </FabContext.Provider>;
}

function editorJSonChangeHandlerFactory(editorCore, articleContext) {
  function editorJSonChange() {
    editorCore.current.save().then((savedData) => {
      articleContext.dispatch({type: updateArticleActionType, payload: {
        nodes: savedData.blocks
      }})
    });
  }
  return editorJSonChange;
}

function titleChangeHandlerFactory(articleContext) {
  function titleChangeHandler(event) {
    articleContext.dispatch({type: updateArticleActionType, payload: {
      title: event.target.value
    }})
  }
  return titleChangeHandler;
}

function descriptionChangeHandlerFactory(articleContext) {
  function descriptionChangeHandler(event) {
    articleContext.dispatch({type: updateArticleActionType, payload: {
      description: event.target.value
    }})
  }
  return descriptionChangeHandler;
}


export function ArticleEditorForm(props) {
  const articleContext = useContext(ArticleContext);

  let article = articleContext.article;
  if (articleContext.modification !== undefined) {
    article = articleContext.modification;
  }
  const { title, description, nodes } = article

  const editorCore = React.useRef(null)
  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance
  }, [])
  return <>
    <Paper variant="header">
      <TextField label="Title" fullWidth multiline variant="standard" defaultValue={title} onChange={titleChangeHandlerFactory(articleContext)}/>
      <TextField label="Description" fullWidth multiline variant="standard" defaultValue={description} onChange={descriptionChangeHandlerFactory(articleContext)}/>
    </Paper>
    <ReactEditorJS
      tools={EDITOR_JS_TOOLS}
      onInitialize={handleInitialize}
      autofocus={true}
      onChange={editorJSonChangeHandlerFactory(editorCore, articleContext)}
      defaultValue={{blocks: nodes}}
    />
  </>
}


export default function ArticleEditorFormPreviewToggle(props) {

  const [showPreview, setShowPreview] = useState(false);
  const togglePreview = () => setShowPreview(!showPreview)
  const articleContext = useContext(ArticleContext);
  const { modification, article } = articleContext;

  if (showPreview) {
    let previewArticle = {...article, ...modification};
    previewArticle.nodes = previewArticle.nodes.map(n => {return {block : n}})
    return <>
      <Scrollbar style={{ width: "100%", height: "100vh" }}>
        <Container maxWidth='md' sx={{ p: 2 }}>
          <Article article={previewArticle} displayFab={false} />
        </Container>
      </Scrollbar>
      <ArticleEditorPreviewFabMenu togglePreview={togglePreview}/>
    </>
  } else {
    return <>
      <Scrollbar style={{ width: "100%", height: "100vh" }}>
        <Container maxWidth='md' sx={{ p: 2 }}>
          <ArticleEditorForm/>
        </Container>
      </Scrollbar>
      <ArticleEditorFormFabMenu togglePreview={togglePreview}/>
    </>
  }

}
