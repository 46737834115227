import React from 'react'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import { CircularProgress } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip"

const fastAccessButtons = {};

export const setFabContext = (newFabs) => { 
  Object.keys(newFabs).forEach((key) => {
    fastAccessButtons[key] = newFabs[key];
  })
};

export const FabContext = React.createContext(fastAccessButtons);

const sx = {
  position: 'absolute',
  bottom: 32,
  right: 32,
}


export function WithLoading(props) {
  const { children, loading } = props;
  if (loading) {
    return <>
      {children}
      <CircularProgress
        size={68}
        sx={{
          color: 'success',
          position: 'absolute',
          top: -6,
          left: -6,
          zIndex: 2,
        }}
      />
    </>
  } else {
    return <>{children}</>
  }
  
}


function fabConsumer(value, theme) {

  const fabs = Object.values(value).map((fabParams, i) => {
    const { fabProps, fabContent } = fabParams
    const { tooltip } = fabProps;
    let fab = <Fab {...fabProps} sx={{zIndex: 2}}>
      {fabContent}
    </Fab>
    if (tooltip) {
      fab = <Tooltip placement="left" arrow title={tooltip}>
        {fab}
      </Tooltip>
    }
    return <p key={i}>
      {fab}
    </p>
  })

  return <Box sx={sx}>
    {fabs}
  </Box>
}

export default function FabMenu() {  
  const theme = useTheme();

  return <FabContext.Consumer>
    {(value) => fabConsumer(value, theme)}
  </FabContext.Consumer>
}