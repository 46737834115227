import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak';
import React, { useState, useContext } from "react";
import { AuthContext, setAuthContext, getAuthContext, tokens } from './AuthContext';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import ArticleSelection from './ArticleSelection'
import { Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { setFabContext } from './FastAccessMenu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { CardMedia } from '@mui/material';
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { Typography } from '@mui/material';
import createThemeConfig from './EgraphTheme'
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import "./App.css"
import LoginModal from './LoginModal';
import { useKeycloak } from '@react-keycloak/web';
import Tooltip from "@mui/material/Tooltip";


function EgraphAppBar(props) {

  const [showLoginModal, setShowLoginModal] = useState(false);
  const authContext = useContext(AuthContext);
  console.log(authContext)
  const { keycloak } = useKeycloak();
  const handleOpen = () => setShowLoginModal(true);
  const handleClose = () => setShowLoginModal(false);
  const history = useNavigate()
  const logout = () => {
    setAuthContext({...authContext, tokens: {}})
    history("/")
    console.log(keycloak)
    keycloak.logout()
  }

  let authButton = null;
  if (tokens.keycloak.token === undefined) {
    authButton = <Tooltip title="Login" arrow>
      <IconButton sx={{ml: 2}} edge="end" aria-label="menu" onClick={handleOpen} >
        <LoginIcon sx={{color:"white"}}/>
      </IconButton>
    </Tooltip>
  } else {
    authButton = <Tooltip title="Logout" arrow>
      <IconButton sx={{ml: 2}} edge="end" aria-label="menu" onClick={logout} >
        <LogoutIcon sx={{color:"white"}}/>
      </IconButton>
    </Tooltip>
  }

  return <AppBar position="static" color="primary">
    <Toolbar>
      <Button edge="start" aria-label="menu" to='/' component={Link} >
        <CardMedia sx={{maxWidth: 50, maxHeight: 40, m: 1, paddingRight: 1}} component="img" image={`${process.env.PUBLIC_URL}/logo_ivory_512.svg`}></CardMedia>
        <CardMedia sx={{maxWidth:110, maxHeight: 50, paddingTop: 1, paddingRight: 1}} component="img" image={`${process.env.PUBLIC_URL}/egraph_ivory_512.svg`}></CardMedia>
      </Button>
      <Box sx={{flexGrow:1}}></Box>
        {authButton}
        <Tooltip title="Selection" arrow>
          <IconButton  sx={{ml: 2}} edge="end" aria-label="menu" to='/selection' component={Link}>
            <DashboardIcon sx={{color:"white"}}/>
          </IconButton>
        </Tooltip>
    </Toolbar>
    <LoginModal open={showLoginModal} onClose={handleClose}/>
  </AppBar>
}



function AuthenticatedContent(props) {

  const [height, setHeight] = useState(window.innerHeight)
  const header_height = 70;
  window.addEventListener('resize',() => setHeight(window.innerHeight));

  const {
    keycloak,
    initOptions,
    loadingKeycloak,
    setAuthContext,
  } = props;
  return <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={initOptions}
    LoadingComponent={loadingKeycloak}
    onEvent={(event, error) => console.log(event, error)}
    silentCheckSsoRedirectUri={window.location.origin + '/silent-check-sso.html'}
    onTokens={setAuthContext}
  >
    <Router>
      <Paper sx={{height: '100vh'}} square elevation={0}>
        <React.StrictMode>
          <Box sx={{ flexGrow: 1, height: header_height }}>
            <EgraphAppBar/>
          </Box>
        </React.StrictMode>
        <Box height={4}/>
        <Box style={{maxHeight: height - header_height - 4 + "px", overflow: 'hidden'}}>
          <AuthContext.Provider value={{setAuthContext, getAuthContext, tokens}}>
            <ArticleSelection/>
          </AuthContext.Provider>
        </Box>
      </Paper>
    </Router>
  </ReactKeycloakProvider>
}




function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  
  function toggleMode() {
    setMode(mode === 'light' ? 'dark' : 'light')
  }

  setFabContext({
    toggleDarkMode: {
      fabProps: {
        color: "secondary",
        "aria-label": "toggle-dark-mode",
        onClick: toggleMode,
        tooltip: mode === "dark" ? "Toggle light mode": "Toggle dark mode"
      },
      fabContent: mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />
    }
  })


  const theme = createTheme(createThemeConfig(mode));

  const initOptions = {
    onLoad: "check-sso",
  }

  const loadingKeycloak = <Box sx={{ width: '100%' }}>
    <LinearProgress />
  </Box>

  
  return (
    <Typography variant="div">
      <ThemeProvider theme={theme}>
        <AuthenticatedContent 
          keycloak={keycloak}
          initOptions={initOptions}
          loadingKeycloak={loadingKeycloak}
          setAuthContext={setAuthContext}
        />
      </ThemeProvider>
    </Typography>
  );
}

export default App;
