import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import FabMenu from './FastAccessMenu';
import Grid from '@mui/material/Grid';
import * as d3 from 'd3';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ArticleCard } from './ArticleCard';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Article from './Article';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import { Scrollbar } from 'react-scrollbars-custom';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function random_curve(epsilon, source, target) {
  return `S ${(target.x + source.x)/2 + epsilon.x} ${(target.y + source.y)/2 + epsilon.y} ,${target.x} ${target.y}`
}

const nodeRadius = 15
const points = [[-nodeRadius, 0], [0, nodeRadius], [nodeRadius, 0], [0, -nodeRadius], [-nodeRadius, 0]];



function linkArc(d) {
  //const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);

  const ret = `
    M${d.source.x},${d.source.y}
    ${random_curve(d.epsilon1, d.source, d.target)}
    M${d.target.x},${d.target.y}
    ${random_curve(d.epsilon2, d.target, d.source)}
  `;
  return ret
}

function LandingPage(props) {

  const ref = useRef(null);
  const [svgWidth, setSvgWidth] = useState(0);
  const [windowWidth, windowHeight] = useWindowSize();
  const [lpArticles, setLpArticles] = useState(null);
  const [lpEdges, setLpEdges] = useState(null);
  const [value, setValue] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const [openModal, setOpenModal] = useState(null);

  function handleArticleClick(event, article) {
    setOpenModal(article)
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_STRAPI_URL}/lp-articles`)
      .catch(console.error)
      .then(strapiResponse => {
        setLpArticles(strapiResponse.data.articles)
        setLpEdges(strapiResponse.data.edges)
      })
  }, [])

  useLayoutEffect(() => {
    setSvgWidth(ref.current.offsetWidth);
  }, []);

  const nodeCurveMaxDelta = 1
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { mode } = palette;

  useEffect(() => {

    if (lpArticles === null) {
      return
    }

    const articles = lpArticles.map(d => {return {
      ...d,
      group: "1",
      epsilon1: getRandomArbitrary(0,100),
      epsilon2: [
        [getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta), getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta)],
        [getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta), getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta)],
        [getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta), getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta)],
        [getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta), getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta)],
        [getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta), getRandomArbitrary(-nodeCurveMaxDelta,nodeCurveMaxDelta)],
      ]
    }})

    const nodeReferences = {};
    const links = [];
    articles.forEach(article => {
      article.nodes.forEach(articleNode => {
        if (nodeReferences[articleNode.id] === undefined) {
          nodeReferences[articleNode.id] = [];
        }
        nodeReferences[articleNode.id].push(article.id);
      })
    })
    lpEdges.forEach(edge => {
      links.push({
        source: articles.find(a => a.nodes.map(n => n.id).includes(edge.from.id)).id,
        target: articles.find(a => a.nodes.map(n => n.id).includes(edge.to.id)).id,
        type: "link",
        value: 1,
      })
    })

    var graph = {
      nodes: articles,
      links: links.map(d => {return {
        ...d,
        epsilon1: {x: getRandomArbitrary(-5,5), y: getRandomArbitrary(-5,5), },
        epsilon2: {x: getRandomArbitrary(-5,5), y: getRandomArbitrary(-5,5), },
      }})
    }

    const curve = d3.line().curve(d3.curveNatural);
    function modNodeCurvePoints(node) {
      const nShifts = Math.round(node.epsilon1 / 20);
      const pointsCopy = [...points]
      for (let i=0; i < nShifts; i++) {
        pointsCopy.shift()
        pointsCopy.push([pointsCopy[0][0] * 1.1, pointsCopy[0][1] * 1.1])
      }
      for (let i = 0; i <pointsCopy.length; i++) {
        pointsCopy[i][0] += node.epsilon2[i][0]
        pointsCopy[i][1] += node.epsilon2[i][1]
      }
      return curve(pointsCopy)
    }

    var svg = d3.select("#test_d3_div"),
      width = +svg.attr("width"),
      height = +svg.attr("height");



    var simulation = d3.forceSimulation()
      .force("link", d3.forceLink().id(function (d) { return d.id; }))
      .force("charge", d3.forceManyBody().strength(-400))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force('collide', d3.forceCollide(d => 30))


    const types = ["link"]
    const fillColor = mode === "light" ? palette.secondary.main : palette.primary.main
    const strokeColor = mode === "light" ? palette.primary.main : palette.secondary.main



    svg.append("defs").selectAll("marker")
      .data(types)
      .join("marker")
      .attr("id", d => `arrow-${d}`)
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 28)
      .attr("refY", 0)
      .attr("markerWidth", 10)
      .attr("markerHeight", 15)
      .attr("orient", "auto")
      .append("path")
      .attr("fill", palette.text.primary)
      .attr("d", 'M0,-5L10,0L0,5');

    var link = svg.append("g")
      .attr("class", "links")
      .attr("color", "black")
      .selectAll("path")
      .data(graph.links)
      .join("path")
      .attr("stroke", palette.text.primary)
      .attr("fill", palette.text.primary)
      .attr("marker-end", d => `url(${new URL(`#arrow-${d.type}`, window.location)})`)

    var nodePlaceholder = svg.append("g")
      .attr("class", "nodes")

    var node = nodePlaceholder.selectAll("g")
      .data(graph.nodes)
      .enter().append("g")

    var nodePath = node.append('path')
      .attr('d', modNodeCurvePoints)
      .style('fill', fillColor)
      .style("stroke", strokeColor)
      .style("stroke-width", 5)

    node.on("mouseover", (d, data1) => {
      nodePath
        .filter((data2)=> data1.id === data2.id)
        .style('stroke', palette.warning.main)
      setValue(lpArticles.findIndex(article => article.id === data1.id))
      setAutoPlay(false);
    })

    node.on("mouseout", (d, data1) => {
      nodePath
        .filter((data2)=> data1.id === data2.id)
        .style('stroke', strokeColor)
      setAutoPlay(true);
    })

    node.on('click', (d, data) => {
      console.log("click", d, data)
    })

    node.call(drag(simulation))

    simulation
      .nodes(graph.nodes)
      .on("tick", ticked);

    simulation.force("link")
      .links(graph.links)
      .distance(function (d) {
        return 100;
      })

    function ticked() {
      link
        .attr("x1", function (d) { return d.source.x; })
        .attr("y1", function (d) { return d.source.y; })
        .attr("x2", function (d) { return d.target.x; })
        .attr("y2", function (d) { return d.target.y; });

      link.attr("d", linkArc);

      node
        .attr("transform", function (d) {
          return "translate(" + d.x + "," + d.y + ")";
        })
    }

    function drag(simulation) {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
      }

      function dragged(event, d) {
        d.x = event.x;
        d.y = event.y;
        d3.select(this).attr("cx", d.x).attr("cy", d.y);
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
      }

      return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);

    }

    function cleanup() {
      node.remove()
      link.remove()
      svg.selectAll("defs").remove()
    }

    return cleanup
  }, [
    mode,
    windowWidth,
    windowHeight,
    lpArticles,
    lpEdges,
    palette.primary.main,
    palette.secondary.main,
    palette.warning.main,
    palette.text.primary,
  ])

  let articleCards = <CircularProgress/>;
  if (lpArticles !== null) {
    articleCards = lpArticles.map((article) => <ArticleCard
      key={article.id}
      {...article}
      xs={12} sm={12} md={12} lg={12}
      sx={{minHeight: "400px", marginLeft: '40px', marginRight: '40px'}}
      onClick={handleArticleClick}
    />)
  }

  return <>
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{marginTop: windowWidth >= 900 ? "120px": "10px"}}
    >
      <Grid item sx={{padding: 2}}>
        <Fade in={true}>
          <Typography variant='h3' textAlign="center">
            A tool to write and share scientific content
          </Typography>
        </Fade>
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} sm={8} md={4} lg={4}>
          <Fade in={true} timeout={1000}>
            <Paper ref={ref}>
              <svg id="test_d3_div" width={svgWidth} height={"400"}></svg>
            </Paper>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={9} md={6} lg={6}>
          <Carousel
            axis="horizontal"
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={autoPlay}
            interval={3000}
            onChange={setValue}
            selectedItem={value}
          >
            {articleCards}
          </Carousel>
        </Grid>
      </Grid>
      <Fade in={true} timeout={1000}>
        <Button sx={{marginTop: "40px", marginBottom: "40px"}} onClick={() => navigate('/selection')} variant="outlined">Go to Selection</Button>
      </Fade>
    </Grid>
    <FabMenu/>
    <Modal
      open={openModal !== null}
      onClose={() => setOpenModal(null)}
      onClick={() => setOpenModal(null)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={ {
        position: 'absolute',
        top: '0%',
        bottom: '0%',
        left: '0%',
        right: '0%',
        pt: 2,
        px: 4,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 3,
        paddingBottom: 3,
        overflow: "hidden",
      }}>
        <Scrollbar style={{ width: "100%", height: "95vh" }}>
          <Container maxWidth='md' sx={{ p: 0 }}><Article article={openModal} displayFab={false}></Article></Container>
        </Scrollbar>
      </Box>
    </Modal>
  </>
}

export default LandingPage;