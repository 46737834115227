import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from "@mui/material/styles";

export function WaitingLogo({value, valueBuffer}) {
  let progress = null;
  if (value !== undefined) {
    progress = <LinearProgress variant="buffer" value={value} valueBuffer={valueBuffer} />
  }
  const theme = useTheme();
  const logo = theme.palette.mode === 'light' ? '/logo_512.svg' : '/logo_ivory_512.svg'
  return <Container sx={{ p:5 }} maxWidth="sm">
    <Grow in={true}>
      <CardMedia sx={{pb: 15, pt: 10}} component="img" image={process.env.PUBLIC_URL + logo} />  
    </Grow>
    {progress}
  </Container>
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
};

export default function WaitingModal() {
  return <Modal open={true}>
    <Box sx={style}>
      <WaitingLogo size="150px" />
    </Box>
  </Modal>
}