import React from 'react'

import axios from 'axios'

export const tokens = {};


async function getStrapiTokens(keycloakToken) {
  if (keycloakToken === undefined) {
    return
  }
  try {
    const strapiResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/auth/keycloak/callback?access_token=${keycloakToken}`);
    const { jwt, user } = strapiResponse.data
    tokens.strapi = {token: jwt, user: user}
  } catch (error) {
    const waitMs = 5000;
    console.error(`Could not retrieve Strapi token, retrying in ${waitMs}`)
    console.error(error);
    await new Promise(resolve => setTimeout(resolve, waitMs));
    await getStrapiTokens(keycloakToken)
  }
}


export const setAuthContext = async (newTokens) => {
  tokens.keycloak = newTokens
  await getStrapiTokens(newTokens.token)
  return tokens
};

export function getAuthContext() {
  return tokens
}

export const AuthContext = React.createContext({
  tokens: tokens,
  setAuthTokens: setAuthContext,
  getAuthTokens: getAuthContext
});