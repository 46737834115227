import axios from 'axios'

export async function getArticle(id, token) {
  const strapiResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/articles/${id}`, {
    headers: {'Authorization': `Bearer ${token}`}
  })
  return strapiResponse.data
}

export async function postArticle(token, title, description, nodes=[]) {
  const strapiResponse = await axios.post(
    `${process.env.REACT_APP_STRAPI_URL}/articles`,
    {
      title: title,
      description: description,
      nodes: nodes,
      order: nodes,
    },
    {
      headers: {'Authorization': `Bearer ${token}`},
    }
  )
  return strapiResponse.data
}

export async function putArticle(token, id, title, description, nodes) {
  const strapiResponse = await axios.put(
    `${process.env.REACT_APP_STRAPI_URL}/articles/${id}`,
    {
      title: title,
      description: description,
      nodes: nodes,
      order: nodes,
    },
    {
      headers: {'Authorization': `Bearer ${token}`},
    }
  )
  return strapiResponse.data
}

export async function deleteArticle(token, id) {
  const strapiResponse = await axios.delete(
    `${process.env.REACT_APP_STRAPI_URL}/articles/${id}`,
    {
      headers: {'Authorization': `Bearer ${token}`},
    }
  )
  return strapiResponse.data
}

export async function postNode(token, articles, authors, block) {
  const strapiResponse = await axios.post(
    `${process.env.REACT_APP_STRAPI_URL}/nodes/`,
    {
      block: block,
      articles: articles,
      authors: authors,
    },
    {
      headers: {'Authorization': `Bearer ${token}`},
    }
  )
  return strapiResponse.data
}

export async function putNode(token, id, articles, authors, block) {
  const strapiResponse = await axios.put(
    `${process.env.REACT_APP_STRAPI_URL}/nodes/${id}`,
    {
      block: block,
      articles: articles,
      authors: authors,
    },
    {
      headers: {'Authorization': `Bearer ${token}`},
    }
  )
  return strapiResponse.data
}

export async function deleteNode(token, id) {
  const strapiResponse = await axios.delete(
    `${process.env.REACT_APP_STRAPI_URL}/articles/${id}`,
    {
      headers: {'Authorization': `Bearer ${token}`},
    }
  )
  return strapiResponse.data
}

export async function getArticlePreview(id) {
  const strapiResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/article-previews/${id}`)
  return strapiResponse.data
}