import React from 'react';
import Container from '@mui/material/Container';
import {
  Routes,
  Route,
} from "react-router-dom";
import { ArticlePage } from './Article';
import { ContentContext } from './ContentContext';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import ArticleEditor from './editor/Editor';
import {ArticleCard, SelectionFabMenu} from './ArticleCard';
import Grid from "@mui/material/Grid";
import { Scrollbar } from 'react-scrollbars-custom';
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LandingPage from './LandingPage'

class ArticleSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: null,
    }
  }

  async _get_strapi_content_list(contentType, strapiToken) {
    if (strapiToken === undefined) {
      const strapiResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/${contentType}`)
      return strapiResponse.data;
    }
    const strapiResponse = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/${contentType}`, {
      headers: { 'Authorization': `Bearer ${strapiToken}`}
    })
    return strapiResponse.data;
  }

  async componentDidMount() {
    const { tokens } = this.context
    if (tokens.keycloak === undefined || tokens.keycloak.token === undefined) {
      const articles = await this._get_strapi_content_list("article-previews");
      this.setState({articles: articles})
    } else if (tokens.strapi === undefined) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      await this.componentDidMount()
    } else {
      const { token } = tokens.strapi;
      const articles = await this._get_strapi_content_list("articles", token);
      this.setState({articles: articles})
    }
  }

  render() {

    if (this.state.articles === null) {
      return <LinearProgress/>
    }

    const { tokens } = this.context

    const articlesCards = this.state.articles.sort(
      (o1, o2) => Date.parse(o1.created_at) < Date.parse(o2.created_at)
    ).map(
      (article, i) => {
        return <ArticleCard key={i} inOrder={i} {...article}/> 
      }
    );

    return <ContentContext.Provider value={{
      content: this.state,
      setContentContext: this.setState.bind(this),
      updateContentContext: this.componentDidMount.bind(this),
    }}
    >
      <Routes>
        <Route exact path="/" element={<LandingPage/>}/>
        { 
          tokens.keycloak.token ? 
          <Route exact path="/article/:id" element={<ArticlePage/>}/> :
          <Route exact path="/article/:id" element={<ArticlePage preview/>}/>
        }
        <Route exact path="/editor/:id" element={<ArticleEditor/>} />
        <Route exact path="/selection" element={
          <Scrollbar style={{ width: "100%", height: "92vh" }}>
            <Container maxWidth='large' sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {articlesCards}
              </Grid>
              <SelectionFabMenu/>
            </Container>
          </Scrollbar>
        }/>
      </Routes>
    </ContentContext.Provider>
  }
}
ArticleSelection.contextType = AuthContext

function injectNavigation(Component) {
  return props => <Component history={useNavigate()} {...props} />;
}

export default injectNavigation(ArticleSelection)