import { useParams, useNavigate } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
  const params = useParams();
  const history = useNavigate();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      params={params}
      history={history}
    />
  );
};

export default withRouter