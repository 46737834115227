import React from 'react';
import {
  ArticleContext,
  newArticleActionType,
  fetchArticleActionType,
  loadArticleActionType,
  errorActionType,
} from './EditorContext';
import { postArticle, getArticle } from '../EditorAPIClient'
import { WaitingLogo } from '../Waiting'
import withRouter from '../WithRouter'
import { ArticleNotFound } from '../Article'


class ArticleEditorLoader extends React.Component {

  // In charge of the CRUD interfacing
  // Handle the routing information

  constructor(props) {
    super(props);
    this.state = {
      creationProgress: {
        value: 0,
        valueBuffer: 0
      },
      status: null
    }
  }

  async fetchThunk() {
    const { params, strapiTokens } = this.props
    const { id } = params;
    const { dispatch } = this.context;
    try {
      const response = await getArticle(id, strapiTokens.token)
      dispatch({type: loadArticleActionType, payload: {
        id: response.id,
        title: response.title,
        description: response.description,
        nodes: response.order.map(key => {
          const node = response.nodes.find(node => node.id === key)
          return {
            ...node.block,
            sid: node.id,
          }
        })
      }})
      this.setState({creationProgress : { value: 80, valueBuffer: 90 }})
    } catch (err) {
      dispatch({type: errorActionType, payload: err.message})
    }
  }

  async newThunk() {
    const newArticle = {
      title: "Unnamed Article",
      description: "",
      nodes: [],
    }
    const { dispatch } = this.context;
    const { strapiTokens, history } = this.props
    try {
      const response = await postArticle(strapiTokens.token, newArticle.title, newArticle.description, newArticle.nodes);
      history('/editor/' + response.id)
      dispatch({type: loadArticleActionType, payload: {
        id: response.id,
        title: response.title,
        description: response.description,
        nodes: response.nodes,
      }})
      this.setState({creationProgress : { value: 20, valueBuffer: 30 }})
    } catch (err) {
      console.error(err)
      dispatch({type: errorActionType, payload: err.message})
    }
  }

  async componentDidMount() {
    const { status, dispatch } = this.context;
    const { params } = this.props;
    const { id } = params;
    if (status === "LOADING") {
      if (id ==="new") {
        this.setState({creationProgress : { value: 10, valueBuffer: 20 }})
        dispatch({ type: newArticleActionType })
        await this.newThunk()
      } else {
        this.setState({creationProgress : { value: 40, valueBuffer: 50 }})
        dispatch({ type: fetchArticleActionType })
        await this.fetchThunk()
      }
    }
  }

  render() {
    const { children, history } = this.props
    const { status } = this.context;

    if (this.state.status !== status) {
      this.setState({ status: status })
    }

    if (status === "LOADING") {
      return <WaitingLogo {...this.state.creationProgress}/>
    }
    if (status === "POSTARTICLE") {
      return <WaitingLogo {...this.state.creationProgress}/>
    }
    if (status === "GETARTICLE") {
      return <WaitingLogo {...this.state.creationProgress}/>
    }
    if (status === "DELETED") {
      history("/selection")
      return null
    }

    if (status === "ERROR") {
      if (this.context.message === "Request failed with status code 401") {
        return <>
          <h1>Access denied</h1>
          <p>You are not allowed to see this content</p>
        </>
      } else if (this.context.message === "Request failed with status code 404") {
        return <ArticleNotFound/>
      }
      return <>
        <h1>Uh-oh, an error occured</h1>
        {this.context.message}
      </>
    }

    return <>{children}</>
  }
}

ArticleEditorLoader.contextType = ArticleContext

export default withRouter(ArticleEditorLoader);