import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import WaitingModal from './Waiting';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import 'katex/dist/katex.min.css'
import FabMenu, { FabContext } from './FastAccessMenu'
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { Typography } from '@mui/material';
import { getArticle, getArticlePreview } from './EditorAPIClient';
import WarningIcon from '@mui/icons-material/Warning';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from "@mui/material/styles";
import withRouter from './WithRouter';
import ReactTimeAgo from 'react-time-ago'
import Fade from '@mui/material/Fade';
import { Scrollbar } from 'react-scrollbars-custom';
import Container from "@mui/material/Container"
import { useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import LoginModal from './LoginModal';

function Delimiter() {
  const theme = useTheme()
  const logo = theme.palette.mode === 'light' ? '/egraph_separator.svg' : '/egraph_separator_ivory.svg'
  return <center><CardMedia sx={{pb: 2, pt: 2, maxWidth: "30px"}} component="img" image={process.env.PUBLIC_URL + logo} /></center>
}


function TextNode(props) {
  const { children } = props;
  return <ReactMarkdown
    remarkPlugins={[
      remarkGfm,
      remarkMath
    ]}
    rehypePlugins={[
      rehypeKatex,
      rehypeRaw
    ]}>
      {children}
  </ReactMarkdown>
}


class BlockRenderer {

  constructor() {
    this.id = 0
  }

  newId() {
    const id = this.id
    this.id += 1
    return id
  }

  math(block) {
    const text  = '## <center> $$' + block.data.text + '$$ </center>';
    return <TextNode key={this.newId()}>{text}</TextNode>
  }

  quote(block) {
    const { text, caption } = block.data;
    return <p key={this.newId()}>
      <center><TextNode>{text}</TextNode></center>
      <Typography variant="caption" display="block" gutterBottom>{caption}</Typography>
    </p>
  }

  paragraph(block) {
    const { text } = block.data
    return <TextNode key={this.newId()}>{text}</TextNode>
  }

  table(block) {
    const { withHeadings, content } = block.data;
    const headerContent = content[0];
    if (withHeadings) {
      const table = content.filter((row, i) => i > 0).map((row, i) => <TableRow key={i}>
        {row.map((cell, j) => <TableCell key={j}><TextNode>{cell}</TextNode></TableCell>)}
      </TableRow>)
      return <Table sx={{ minWidth: 650 }} key={this.newId()}>
        <TableHead>
          <TableRow>
            {headerContent.map((content, i) => <TableCell key={i}>{content}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {table}
        </TableBody>
      </Table>
    } else {
      const table = content.map((row, i) => <TableRow key={i}>
        {row.map((cell, j) => <TableCell key={j}><TextNode>{cell}</TextNode></TableCell>)}
      </TableRow>)
      return <Table sx={{ minWidth: 650 }} key={this.newId()}>
        <TableBody>
          {table}
        </TableBody>
      </Table>;
    }
  }

  list(block) {
    const { style, items } = block.data;
    if (style === "unordered") {
      return <ul key={this.newId()}>
        {items.map((item, i) => <li key={i}>{item}</li>)}
      </ul>
    }
    if (style === "ordered") {
      return <ol key={this.newId()}>
        {items.map((item, i) => <li key={i}>{item}</li>)}
      </ol>
    }

    throw new Error("Unsupported style: " + style)
  }

  warning(block) {
    const { title, message } = block.data;
    return <Paper variant='warning' key={this.newId()}>
      <h5>{title}</h5>
      <p><WarningIcon sx={{paddingRight: 1, paddingLeft: 1, m: 1}}/> {message}</p>
    </Paper>
  }

  code(block) {
    const { code } = block.data;
    return <Paper elevation={0} sx={{p: 1, m: 1, boxShadow:'0 0 1px 1px #000 inset'}} key={this.newId()}>
      <code>{code}</code>
    </Paper>
  }

  header(block) {
    const { level, text } = block.data
    return <Typography variant={'h' + level} key={this.newId()}>{text}</Typography>
  }

  checklist(block) {
    const { items } = block.data;
    return <FormGroup key={this.newId()}>
      {items.map((item, u) => <FormControlLabel disabled control={<Checkbox defaultChecked={item.checked} />} label={item.text} />)}
    </FormGroup>
  }

  delimiter() {
    return <Delimiter key={this.newId()}/>
  }

  previewfiller(block) {
    const { handleOpen } = block.data
    return <Box sx={{}}>
      <Typography sx={{textAlign: "center"}} variant={'h4'}>This article is reserved for registered users</Typography>
      <Typography sx={{textAlign: "center", paddingTop:3}}>
        <Button variant="outlined" onClick={handleOpen}>Please login to see more</Button>
      </Typography>
    </Box>
  }
}


function ArticleFastAccessButtons(props) {
  const { edit, articleId, preview } = props
  const { toggleDarkMode } = useContext(FabContext);
  const history = useNavigate();

  if (preview) {
    return <FabContext.Provider value={{ toggleDarkMode: toggleDarkMode }}>
      <FabMenu/>
    </FabContext.Provider>
  }
  
  const newContext = {
    editArticle: {
      fabProps: {
        color: "info",
        "aria-label": "edit-article",
        onClick: () => history(`/editor/${articleId}`),
        disabled: !edit,
        tooltip: "Edit this article"
      },
      fabContent: <EditIcon/>
    },
    toggleDarkMode,
  }
  return <FabContext.Provider value={newContext}>
    <FabMenu/>
  </FabContext.Provider>;
}

export function ArticleNotFound(props) {
  return <Paper sx={{ p: 3 }}>
    <Typography variant="h1">Article not found :(</Typography>
    <p>Maybe the page you are looking for has been removed, or you typed in the wrong URL</p>
  </Paper>
}

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      showLoginModal: false,
    }
  }

  async componentDidMount() {
    const { article, preview } = this.props;
    if (article === undefined) {
      let { id } = this.props.params;
      try {
        if (!preview) {
          const { token } = this.context.tokens.strapi;
          const strapiResponse = await getArticle(id, token);
          this.setState({article: strapiResponse})
        } else {
          const strapiResponse = await getArticlePreview(id);
          this.setState({article: strapiResponse})
        }
      } catch (error) {
        this.setState({error: error})
      }
    } else {
      this.setState({article: article})
    }
  }

  render() {
    const { article, error } = this.state;
    const { preview } = this.props;

    if (error !== undefined) {

      console.error(error)
      if (error.response.status === 404) {
        return <ArticleNotFound/>
      } else {
        return <Paper>
          An error occured while trying to render the article. Please contact your administrator
        </Paper>
      }
    }

    if (article === null) {
      return <WaitingModal/>
    }

    const {
      published_at,
      updated_at,
      description,
      nodes,
      title,
      order
    } = article

    const renderer = new BlockRenderer()
    const ordered = [];
    if (order !== undefined) {
      order.forEach(id => {
        ordered.push(nodes.find(n => n.id === id))
      })
    } else {
      nodes.forEach(node => ordered.push(node))
    }

    const blocks = ordered.map(node => node.block)

    const handleClose = () => this.setState({showLoginModal: false});

    if (preview) {
      blocks.push({type: "paragraph", data: {text: "[...]"}})
      blocks.push({type: "delimiter"})
      blocks.push({
        type: "previewfiller", 
        data: {
          handleOpen: () => this.setState({showLoginModal: true}),
          
        }
      })
    }
    
    const components = blocks.map(block => {
      if (renderer[block.type] !== undefined) {
        return renderer[block.type](block)
      }
      this.setState({error: `Undefined block type ${block.type}. This is a bug in Egraph, please contact your administrator`})
      return null;
    })

    return <>
      <Fade in={true} timeout={250}>
        <Paper variant='header'>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="p">{description}</Typography>
        </Paper>
      </Fade>
      <Paper elevation={0} sx={{ p: 3, marginTop: 2, marginBottom: 2}}>
        {components}
      </Paper>
      <Fade in={true} timeout={500}>
        <Paper variant='footer' sx={{ marginTop: 2, marginBottom: 8}}>
          {published_at !== undefined ? <p>Published: <ReactTimeAgo date={published_at} locale="en-US"/></p>: null}
          {updated_at !== undefined ? <p>Updated: <ReactTimeAgo date={updated_at} locale="en-US"/></p>: null}
        </Paper>
      </Fade>
      <LoginModal open={this.state.showLoginModal} onClose={handleClose}/>
    </>
  }
}
Article.contextType = AuthContext;

const RouterArticle = withRouter(Article);

export default RouterArticle

export function ArticlePage(props) {
  const { id } = useParams();
  const { displayFab: displayFabProp, preview } = props;
  
  const displayFab = props.displayFab === undefined ? true : displayFabProp
  return <>
    <Scrollbar style={{ width: "100%", height: "92vh" }}>
      <Container maxWidth='md' sx={{ p: 2 }}>
        <RouterArticle {...props}/>
      </Container>
    </Scrollbar>
    {displayFab ? <ArticleFastAccessButtons edit={true} articleId={id} preview={preview}/> : <></>}
  </>
}