import React, { useContext, useReducer } from 'react';
import { ContentContext } from '../ContentContext';

export const newArticleActionType = "new";
export const fetchArticleActionType = "fetch";
export const loadArticleActionType = "load";
export const updateArticleActionType = "update";
export const saveArticleActionType = "save";
export const doneSavingArticleActionType = "done";
export const errorActionType = "error";
export const deleteActionType = "delete";

const actionStateGraph = {
  LOADING: [newArticleActionType, fetchArticleActionType],
  POSTARTICLE: [loadArticleActionType, errorActionType],
  GETARTICLE: [loadArticleActionType, errorActionType],
  READY: [updateArticleActionType, deleteActionType],
  DIRTY: [saveArticleActionType, updateArticleActionType, deleteActionType],
  PUTARTICLE: [doneSavingArticleActionType, errorActionType],
  DELETED: [],
}

const articleData = { status: "LOADING" };

export const ArticleContext = React.createContext(articleData);


function articleReducer(state, {type, payload}) {
  const { status } = state;
  const contentContext = {
    content: state.content,
    setContentContext: state.setContentContext,
    updateContentContext: state.updateContentContext,
  }
  if (!actionStateGraph[status].includes(type)) {
    return state
  }
  switch (type) {
    case newArticleActionType:
      contentContext.updateContentContext();
      return {
        status: "POSTARTICLE",
        ...contentContext,
      };
    case fetchArticleActionType:
      return {
        status: "GETARTICLE",
        ...contentContext,
      };
    case loadArticleActionType:
      return {
        status: "READY",
        article: payload,
        ...contentContext,
      }
    case updateArticleActionType:
      return {
        status: "DIRTY",
        article: state.article,
        modification: {id: state.article.id, ...state.modification, ...payload},
        ...contentContext,
      }
    case saveArticleActionType:
      return {
        status: "PUTARTICLE",
        article: payload,
        ...contentContext,
      }
    case doneSavingArticleActionType:
      contentContext.updateContentContext();
      return {
        status: "READY",
        article: state.article,
        ...contentContext,
      }
    case deleteActionType:
      contentContext.updateContentContext();
      return {
        status: "DELETED"
      }
    case errorActionType:
      return {
        status: "ERROR",
        message: payload,
        ...contentContext,
      }
    default:
      throw new Error(`Invalid article action type provided ${type}`)
  }
}

export function ArticleContextProvider(props) {
  let { value } = props;
  if (value === undefined) {
    value = articleData;
  }
  const { content, setContentContext, updateContentContext } = useContext(ContentContext);
  value = {
    ...value,
    content: content,
    setContentContext: setContentContext,
    updateContentContext: updateContentContext,
  }
  const [state, dispatch] = useReducer(articleReducer, value);
  const { children } = props;
  return <ArticleContext.Provider value={{...state, dispatch}}>
    {children}
  </ArticleContext.Provider>
}