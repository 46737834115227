import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useKeycloak } from "@react-keycloak/web";


export default function LoginModal(props) {

  const authContext = useContext(AuthContext)
  const { keycloak } = useKeycloak(props);
  console.log(authContext)

  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50vw",
  };

  return <Modal {...props}>
    <Box sx={style}>
      <Paper sx={{p: 3}}>
        <Box sx={{textAlign: 'center', pb:2}}>
          <Typography variant="h4" sx={{ pb:2}}>Login</Typography>
          <Button variant="outlined" onClick={() => keycloak.login()}>Egraph SSO</Button>
        </Box>
        <Typography variant="h5" sx={{textAlign: 'center'}}>You do not have login information?</Typography>
        <Typography>
          Egraph is still under construction and testing.
          Hence registration is not open yet.
          Thank you for your understanding :)
        </Typography>
      </Paper>
    </Box>
  </Modal>
}