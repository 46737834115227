import tinycolor from 'tinycolor2';
import '@fontsource/roboto/700.css';
import "@fontsource/caveat";

export const themeColors = {
  russianViolet: '#0D0630',
  prussianBlue: '#18314F',
  YInMnBlue: '#384E77',
  minionYellow: '#E2DB50',
  ivory: '#F9FBEF',
  lightRed: '#E57373',
  darkRed: '#D32F2F',
  middleBlue: '#94D5DB',
  lightGreen: '#81c784',
  darkGreen: '#388e3c',
  lightOrange: '#ffb74d',
  darkOrange: '#f57c00'
}


export function makePalette(mode, light, dark) {
  let main = light
  if (dark === undefined) {
    dark = light
  }
  if (dark !== undefined && mode === "dark") {
    main = dark
  }
  return {
    light: light,
    dark: dark,
    main: main,
  }
}



export function getTextColor(color) {
  if (tinycolor(color).getBrightness() < 100) {
    return '#FFF'
  } else {
    return '#000'
  }
}

export function createColorVariants(palette, variantList, prop='color', sx={}) {
  const res = variantList.map(name => {
    const props = {}
    props[prop] = name
    return [
      {
        props: props,
        style: {
          backgroundColor: palette[name][palette.mode],
          "&:hover": {
            backgroundColor: palette[name][palette.mode],
          },
          color: getTextColor(palette[name][palette.mode]),
          ...sx
        }
      }
    ]
  }).flat()
  return res
}


export function createPalette(mode) {
  const palette = {
    mode: mode,
    primary: makePalette(mode, themeColors.russianViolet),
    secondary: makePalette(mode, themeColors.middleBlue),
    neutral: makePalette(mode, themeColors.prussianBlue),
    info: makePalette(mode, themeColors.minionYellow),
    danger: makePalette(mode, themeColors.lightRed, themeColors.darkRed),
    success: makePalette(mode, themeColors.lightGreen, themeColors.darkGreen),
    warning: makePalette(mode, themeColors.lightOrange, themeColors.darkOrange),
  }
  return palette
}


export default function createThemeConfig(mode) {

  const palette = createPalette(mode)

  return {
    typography: {
      fontFamily: ['Roboto']
    },
    status: {
      danger: themeColors.lightRed
    },
    palette: palette,
    components: {
      MuiAppBar: {
        defaultProps: {
          enableColorOnDark: true,
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderTopLeftRadius: '255px 7px',
            bordeTopRightRadius: '7px 225px',
            borderBottomRightRadius: '225px 7px',
            borderBottomLeftRadius: '7px 255px',
            backgroundColor: mode === 'light' ? themeColors.ivory : themeColors.prussianBlue,
            textAlign: "justify",
            fontSize: "18px",
            lineHeight: "32px",
          },
        },
        variants: [
          { 
            props: { "variant": "header" },
            style: {
              padding: '18px',
              border: `solid 3px ${palette.neutral.main}`,
              backgroundColor: palette.secondary.main,
              color: getTextColor(palette.secondary.main),
            }
          },
          { 
            props: { "variant": "card" },
            style: {
              padding: '18px',
              border: `solid 3px ${palette.neutral.main}`,
              backgroundColor: palette.secondary.main,
              color: getTextColor(palette.secondary.main),
              cursor: "pointer",
              textAlign: "justify",
            }
          },
          { 
            props: { "variant": "footer" },
            style: {
              padding: '18px',
              border: `solid 3px ${palette.neutral.main}`,
              backgroundColor: palette.secondary.main,
              color: getTextColor(palette.secondary.main),
            }
          },
          { 
            props: { "variant": "warning" },
            style: {
              padding: '10px',
              border: `solid 1px ${palette.neutral.main}`,
              backgroundColor: palette.warning.main,
              color: getTextColor(palette.secondary.main),
              textAlign: "justify",
            }
          },
        ]
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: 'Caveat'
          },
          h2: {
            fontFamily: 'Caveat'
          },
          h3: {
            fontFamily: 'Caveat'
          },
          h4: {
            fontFamily: 'Caveat'
          },
          h5: {
            fontFamily: 'Caveat'
          },
          h6: {
            fontFamily: 'Caveat'
          },
        }
      },
      MuiFab: {
        variants: createColorVariants(palette, ["neutral", "info", "danger", "success", "warning"])
      },
      MuiButton: {
        variants: [
          { 
            props: { "variant": "danger" },
            style: {
              backgroundColor: palette.warning.main,
              display: "inline-block",
              '&:hover': {
                background: palette.danger.main,
                color: palette.secondary.main,
              },
              color: getTextColor(palette.secondary.main),
            }
          },
          { 
            props: { "variant": "outlined" },
            style: {
              padding: '18px',
              border: `solid 3px ${palette.neutral.main}`,
              backgroundColor: palette.secondary.main,
              '&:hover': {
                background: palette.primary.main,
                color: palette.secondary.main,
                border: `solid 3px ${palette.neutral.main}`,
              },
              color: getTextColor(palette.secondary.main),
            }
          }
        ]
      }
    },
  }
}