import { useContext } from 'react'
import EditorLoader from "./EditorLoader";
import { ArticleContextProvider } from "./EditorContext";
import { AuthContext } from "../AuthContext"
import ArticleEditorFormPreviewToggle from './EditorForm';

export default function ArticleEditor() {
  const { tokens } = useContext(AuthContext);
  return <ArticleContextProvider>
    <EditorLoader strapiTokens={tokens.strapi}>
      <ArticleEditorFormPreviewToggle/>
    </EditorLoader>
  </ArticleContextProvider>
}