import Grid from "@mui/material/Grid";
import { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import FabMenu, { FabContext } from './FastAccessMenu';
import AddIcon from '@mui/icons-material/Add';
import ReactTimeAgo from 'react-time-ago'
import Fade from '@mui/material/Fade';
import LinesEllipsis from 'react-lines-ellipsis'
import { AuthContext } from "./AuthContext";


export function SelectionFabMenu(props) {
  const { toggleDarkMode } = useContext(FabContext);
  const { tokens } = useContext(AuthContext);
  const history = useNavigate();
  if (tokens.keycloak !== undefined && tokens.keycloak.token !== undefined) {
    const newContext = {
      addArticle: {
        fabProps: {
          color: "primary",
          "aria-label": "add-article",
          onClick: () => history('/editor/new'),
          tooltip: "Add an article"
        },
        fabContent: <AddIcon/>
      },
      toggleDarkMode,
    }
    return <FabContext.Provider value={newContext}>
      <FabMenu/>
    </FabContext.Provider>;
  }
  return <FabMenu/>
}

export function ArticleCard(props) {
  const history = useNavigate();
  const {
    title,
    published_at,
    description,
    id,
    inOrder,
  } = props;
  
  let handler = props.onClick;
  if (handler === undefined) {
    handler = () => history(`/article/${id}`)
  } else {
    handler = (event) => props.onClick(event, props);
  }

  let {
    xs, md, lg, sx
  } = {
    xs:12 , md:6, lg:4,
    ...props
  }

  if (inOrder !== undefined) {
    return <Grid item xs={xs} md={md} lg={lg}>
      <Fade in={true} timeout={400 + 150 * inOrder}>
        <Paper variant='card' onClick={handler} sx={sx}>
          <Typography variant="h3">{title}</Typography>
          <LinesEllipsis
            text={description}
            maxLine='4'
            ellipsis='...'
            trimRight
            basedOn='letters'
          />
          <p><ReactTimeAgo date={published_at} locale="en-US"/></p>
        </Paper>
      </Fade>
    </Grid>
  }
  
  return <Grid item xs={xs} md={md} lg={lg}>
    <Paper variant='card' onClick={handler} sx={sx}>
      <Typography variant="h3">{title}</Typography>
      <LinesEllipsis
        text={description}
        maxLine='7'
        ellipsis='...'
        trimRight
        basedOn='letters'
      />
      <p><ReactTimeAgo date={published_at} locale="en-US"/></p>
    </Paper>
  </Grid>
}